import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiURL}/api/dm/ky_baocao`;

@Injectable({
    providedIn: 'root'
})
export class Ky_baocaoService {

    constructor(private http: HttpClient) { }

    get_all(prmDK) {
        return this.http.get<any[]>(`${baseUrl}/getall?prmDK=` + prmDK);
    }
}
