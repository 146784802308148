import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiURL}/api/config_hethong`;

@Injectable({
  providedIn: 'root'
})
export class Config_hethongService {

  constructor(private http: HttpClient) { }

  get_all() {
    return this.http.get<any[]>(`${baseUrl}/getall`);
  }

  insert(model: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/create`,
      {
        'prmJsonData': model
      },
      { withCredentials: true }
    );
  }

  update(model: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/update`,
      {
        'prmJsonData': model
      },
      { withCredentials: true }
    );
  }
}
