import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Chucvu } from '@app/_models/dm/chucvu';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiURL}/api/dm/chucvu`;

@Injectable({
  providedIn: 'root'
})
export class ChucvuService {

  constructor(private http: HttpClient) { }

  get_all(prmDK: number, prmLOAIHINH: number) { 
    return this.http.get<Chucvu[]>(`${baseUrl}?prmDK=` + prmDK + '&prmLOAIHINH=' + prmLOAIHINH);
  }

  get_trangthai(trangthai: number) {
    return this.http.get<Chucvu>(`${baseUrl}/trang-thai/${trangthai}`);
  }

  chuyen_trangthai(prmID: number, prmNGUOI_CAPNHAT: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/trangthai?prmID=` + prmID + `&prmNGUOI_CAPNHAT=` + prmNGUOI_CAPNHAT,
      { withCredentials: true }
    );
  }

  insert(model: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/create`,
      {
        'prmJsonData': model
      },
      { withCredentials: true }
    );
  }

  update(model: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/update`,
      {
        'prmJsonData': model
      },
      { withCredentials: true }
    );
  }

  delete(prmID: number, prmNGUOI_NHAP: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/delete?prmID=` + prmID + `&prmNGUOI_NHAP=` + prmNGUOI_NHAP,
      { withCredentials: true }
    );
  }
}
