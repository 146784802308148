import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Donvi } from '@app/_models/dm/donvi';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiURL}/api/dm/donvi`;

@Injectable({
  providedIn: 'root'
})
export class DonviService {
  items: any;

  constructor(
    private http: HttpClient
  ) { }

  getdv_bynsd(NSD) {
    return this.http.get<Donvi[]>(`${baseUrl}/getdv_bynsd?prmNSD=` + NSD);
  }

  get_tree(MATINH) {
    return this.http.get<Donvi[]>(`${baseUrl}/getall?prmMATINH=` + MATINH);
  }

  get(id: number) {
    return this.http.get<Donvi>(`${baseUrl}/get?id=` + id);
  }

  insert(model: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/create`,
      {
        'prmJsonData': model
      },
      { withCredentials: true }
    );
  }

  update(model: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/update`,
      {
        'prmJsonData': model
      },
      { withCredentials: true }
    );
  }

  delete(prmID: number, prmNGUOI_NHAP: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/delete?prmID=` + prmID + `&prmNGUOI_NHAP=` + prmNGUOI_NHAP,
      { withCredentials: true }
    );
  }  
}
